
import { defineComponent, ref } from 'vue';
import Card from 'primevue/card';

import ProductCatalog from '@/components/Products/ProductCatalog.vue';

export default defineComponent({
  components: {
    Card,
    'product-catalog': ProductCatalog,
  },
  beforeCreate() {
    if (this.$options.components) {
      this.$options.components.ProductForm = require('../components/Products/ProductForm').default;
    }
  },
  setup() {
    const dialog = ref(false);
    const id = ref<number>();
    const editMode = ref(false);
    const update = ref<Function>();

    // eslint-disable-next-line
    const openDialog = (event: any) => {
      dialog.value = true;
      id.value = event.id;
      editMode.value = event.editMode;
      update.value = event.update;
    }

    return {
      dialog,
      id,
      editMode,
      update,
      openDialog
    }
  }
});
