
import { computed, defineComponent, onMounted, reactive, ref } from 'vue';

import { useToast } from 'primevue/usetoast';
import Dialog from 'primevue/dialog';
import Galleria from 'primevue/galleria';
import Divider from 'primevue/divider';

import { Part } from '@/classes/models/Part';
import { PartApi } from '@/classes/api/PartApi';
import CustomError from '@/classes/models/CustomError';
import { useRouter } from 'vue-router';
import { Photo } from '@/classes/Photo';

interface GalleriaImage {
  itemImageSrc: string;
  thumbnailImageSrc: string;
  alt: string;
  title: string;
}

export default defineComponent({
  name: 'ProductItem',
  emits: ['update:visible'],
  components: {
    Dialog,
    Galleria,
    Divider,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    visible: Boolean,
  },

  setup(props, ctx) {
    const item = reactive<Part>(new Part({}));
    const dialog = ref(true);
    const images = ref<GalleriaImage[]>([]);

    const api = new PartApi();
    const toast = useToast();
    const router = useRouter();

    const responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 5,
      },
      {
        breakpoint: '768px',
        numVisible: 3,
      },
      {
        breakpoint: '560px',
        numVisible: 1,
      },
    ];

    const inputVisible = computed({
      get: () => props.visible,
      set: val => {
        ctx.emit('update:visible', val);
      },
    });

    const generateImages = (itmImages?: Photo[]) => {
      const generated: GalleriaImage[] = [];
      if (itmImages && itmImages.length > 0) {
        for (const image of itmImages) {
          generated.push({
            itemImageSrc: `${process.env.VUE_APP_IMAGE_URL}${image.url}`,
            thumbnailImageSrc: `${process.env.VUE_APP_IMAGE_URL}${image.url_}`,
            alt: 'alt',
            title: 'title',
          });
        }
      }
      images.value = generated;
    };

    onMounted(async () => {
      try {
        const result = await api.fetchById(+props.id);

        item.replaceBy(result);
        generateImages(result.images);
      } catch (error) {
        if (error instanceof CustomError) {
          error.show('error', 5000, toast);
        } else {
          console.log(error);
        }
      }
    });

    const getHeaderTitle = (): string => {
      let headerTitle = '';
      if (item) {
        item.nomenclature && (headerTitle = item.nomenclature.name);
        item.marka && (headerTitle += ' ' + item.marka);
        item.model && (headerTitle += ' ' + item.model);
        item.year && (headerTitle += ' ' + item.year);

        return headerTitle;
      }
      return 'Запчасть';
    };

    const onHide = () => {
      router.back();
    };

    return {
      inputVisible,
      dialog,
      item,
      images,
      responsiveOptions,
      onHide,
      getHeaderTitle,
    };
  },
});
